import { isMobile } from 'mobile-device-detect'

const slideBanner = function (arg) {
  var loopTime = arg.time || 3000
  var container = arg.container
  var isLoop = arg.isLoop || false
  var loopCountCurrent = arg.initCount || 0
  if (!container) {
    console.error('slideBanner: not set container')
    return
  }
  var loop
  container.classList.remove('slide_banner_loading')
  var slideBannerList = container.querySelectorAll('li')
  var slideBannerListLength = slideBannerList.length
  var containerUl = container.getElementsByTagName('ul')[0]
  var touchX = 0

  var title = document.querySelectorAll('.slide_banner_title')
  containerUl.setAttribute('data-count', loopCountCurrent)
  document.body.setAttribute('data-count', loopCountCurrent)

  initDot(slideBannerList)
  initNext()
  initPrev()

  function updateTitle (type) {
    if (type === 'remove') {
      title[loopCountCurrent].classList.add('hide')
    }
    if (type === 'add') {
      title[loopCountCurrent].classList.remove('hide')
    }
  }

  function loopStart () {
    loop = setInterval(function () {
      slideBannerList[loopCountCurrent].classList.add('hide')
      updateDots('remove')
      updateTitle('remove')
      loopCountCurrent++
      if (loopCountCurrent === slideBannerListLength) {
        loopCountCurrent = 0
      }
      slideBannerList[loopCountCurrent].classList.remove('hide')
      updateDots('add')
      document.querySelector('#slide_banner_next').setAttribute('data-count', loopCountCurrent)
      containerUl.setAttribute('data-count', loopCountCurrent)
      document.body.setAttribute('data-count', loopCountCurrent)
      updateTitle('add')
    }, loopTime)
  }

  function initDot (list) {
    var dots = document.createElement('div')
    dots.setAttribute('id', 'slide_banner_dots')
    for (var i = 0; i < list.length; i++) {

      var dot = document.createElement('div')
      dot.setAttribute('class', 'slide_banner_dot')
      dots.appendChild(dot)
    }
    container.appendChild(dots)
    updateDots('add')
  }

  function updateDots (type) {
    var dot = document.querySelectorAll('.slide_banner_dot')[loopCountCurrent]
    if (type === 'remove') {
      dot.classList.remove('focus')
    }
    if (type === 'add') {
      dot.classList.add('focus')
    }
  }

  function initNext () {
    let next = document.createElement('div')
    let div = document.createElement('div')
    next.setAttribute('id', 'slide_banner_next')
    next.setAttribute('data-count', loopCountCurrent)
    document.body.setAttribute('data-count', loopCountCurrent)
    next.appendChild(div)
    next.addEventListener('click', function () {
      updateSlide('next')
    })
    container.appendChild(next)
  }

  function initPrev () {
    let prev = document.createElement('div')
    let div = document.createElement('div')
    prev.setAttribute('id', 'slide_banner_prev')
    prev.setAttribute('data-count', loopCountCurrent)
    document.body.setAttribute('data-count', loopCountCurrent)
    prev.appendChild(div)
    prev.addEventListener('click', function () {
      updateSlide('prev')
    })
    container.appendChild(prev)
  }

  function updateSlide (type) {
    clearInterval(loop)
    slideBannerList[loopCountCurrent].classList.add('hide')
    updateDots('remove')
    updateTitle('remove')
    if (type === 'next') {
      loopCountCurrent++
      if (loopCountCurrent === slideBannerListLength) {
        loopCountCurrent = 0
      }
    } else {
      loopCountCurrent--
      if (loopCountCurrent < 0) {
        loopCountCurrent = slideBannerListLength - 1
      }
    }
    slideBannerList[loopCountCurrent].classList.remove('hide')
    updateDots('add')
    document.querySelector('#slide_banner_next').setAttribute('data-count', loopCountCurrent)
    containerUl.setAttribute('data-count', loopCountCurrent)
    document.body.setAttribute('data-count', loopCountCurrent)
    loopStart()
    updateTitle('add')

  }

  function startMove (obj, iTarget, transTime) {

    clearInterval(obj.timer)

    var count = transTime || Math.floor(500 / 30)
    var start = touchX
    var dis = iTarget - start
    var n = 0
    var halfW = containerUl.offsetWidth / 2

    obj.timer = setInterval(function () {
      n++
      var a = 1 - n / count

      touchX = start + dis * (1 - a * a * a)
      if (touchX < 0) {
        containerUl.style.WebkitTransform = 'translateX(' + touchX % halfW + 'px)'
      } else {
        containerUl.style.WebkitTransform = 'translateX(' + (touchX % halfW - halfW) % halfW + 'px)'
      }

      if (n == count) {
        clearInterval(obj.timer)
      }

    }, 30)
  }

  function initSlide (list) {
    for (var i = 0; i < list.length; i++) {
      list[i].classList.remove('hide')
      title[i].classList.remove('hide')
    }
    var allLi = containerUl.children
    var counter = 0
    var disX = 0
    var downX = 0

    // clone for loop render

    containerUl.innerHTML += containerUl.innerHTML

    containerUl.style.width = allLi.length * allLi[0].offsetWidth + 'px'

    window.addEventListener('resize', function () {
      containerUl.style.width = allLi.length * allLi[0].offsetWidth + 'px'
      startMove(containerUl, -loopCountCurrent * allLi[0].offsetWidth, Math.floor(300 / 30))
    })

    if (isLoop) {
      containerUl.addEventListener('touchstart', function (ev) {

        downX = ev.targetTouches[0].pageX
        disX = downX - touchX

        clearInterval(loop)
        clearInterval(containerUl.timer)


      }, false)
      function fnMove (ev) {
        var halfW = containerUl.offsetWidth / 2
        touchX = ev.targetTouches[0].pageX - disX
        if (touchX < 0) {
          containerUl.style.WebkitTransform = 'translateX(' + touchX % halfW + 'px)'
        } else {
          containerUl.style.WebkitTransform = 'translateX(' + (touchX % halfW - halfW) % halfW + 'px)'
        }
        // ev.preventDefault()
      }

      function fnEnd (ev) {

        var upX = ev.changedTouches[0].pageX

        updateDots('remove')
        if (Math.abs(downX - upX) < 50) {
          startMove(containerUl, -counter * allLi[0].offsetWidth, Math.floor(300 / 30))
        } else {
          if (downX > upX) { //++
            loopCountCurrent++
            if (loopCountCurrent === slideBannerList.length) {
              loopCountCurrent = 0
            }
            counter++
            startMove(containerUl, -counter * allLi[0].offsetWidth, Math.floor(300 / 30))
          } else {
            loopCountCurrent--
            if (loopCountCurrent < 0) {
              loopCountCurrent = slideBannerList.length - 1
            }
            counter--
            startMove(containerUl, -counter * allLi[0].offsetWidth, Math.floor(300 / 30))

          }
        }
        updateDots('add')
        document.body.setAttribute('data-count', loopCountCurrent)
        loopStartMobile()
        // containerUl.removeEventListener('touchend', fnEnd, false)
        // containerUl.removeEventListener('touchmove', fnMove, false)

      }

      containerUl.addEventListener('touchmove', fnMove, false)
      containerUl.addEventListener('touchend', fnEnd, false)
    }

    function loopStartMobile () {
      loop = setInterval(function () {
        updateDots('remove')
        loopCountCurrent++
        if (loopCountCurrent === slideBannerList.length) {
          loopCountCurrent = 0
        }
        counter++
        startMove(containerUl, -counter * allLi[0].offsetWidth)
        updateDots('add')
      }, loopTime)
    }

    if (isLoop) {
      loopStartMobile()
    } else {
      if (loopCountCurrent) {
        containerUl.style.WebkitTransform = 'translateX(' + (0 - loopCountCurrent * allLi[0].offsetWidth).toString() + 'px)';
      }
    }
  }

  if (isMobile) {
    initSlide(slideBannerList)
  } else {
    slideBannerList[loopCountCurrent].classList.remove('hide')
    title[loopCountCurrent].classList.remove('hide')
    if (isLoop) {
      loopStart()
    }
  }
}

export default slideBanner
