<template>
  <div id="app">
    <section id="slide_banner" class="slide_banner_loading">
      <h1>{{ $t('slogan') }}</h1>
      <ul>
        <li class="hide">
          <section class="container">
            <section class="slide_banner_title hide">
              <h2>{{ $t('feature1') }}</h2>
              <article>{{ $t('feature1_content') }}</article>
              <a
                class="dol_btn"
                href="https://media.bizny.app/common/redirect/bizny-track?bizny_biznyweb"
                data-count="feature_1"
              >Download</a>
            </section>
            <picture>
              <source srcset="./assets/pic_bizny_web_01-375@2x.png 2x" media="(max-width: 375px)">
              <source srcset="./assets/pic_bizny_web_01-640@2x.png 2x" media="(max-width: 640px)">
              <img src="./assets/pic_bizny_web_01@1x.png" srcset="./assets/pic_bizny_web_01@2x.png 2x" :alt="$t('feature1')" />
            </picture>
          </section>
        </li>
        <li class="hide">
          <section class="container">
            <section class="slide_banner_title hide">
              <h2>{{ $t('feature2') }}</h2>
              <article>{{ $t('feature2_content') }}</article>
              <a
                class="dol_btn"
                href="https://media.bizny.app/common/redirect/bizny-track?bizny_biznyweb"
                data-count="feature_2"
              >Download</a>
            </section>
            <picture>
              <source srcset="./assets/pic_bizny_web_02-375@2x.png 2x" media="(max-width: 375px)">
              <source srcset="./assets/pic_bizny_web_02-640@2x.png 2x" media="(max-width: 640px)">
              <img src="./assets/pic_bizny_web_02@1x.png" srcset="./assets/pic_bizny_web_02@2x.png 2x" :alt="$t('feature2')" />
            </picture>
          </section>
        </li>
        <li class="hide">
          <section class="container">
            <section class="slide_banner_title hide">
              <h2>{{ $t('feature3') }}</h2>
              <article>{{ $t('feature3_content') }}</article>
              <a
                class="dol_btn"
                href="https://media.bizny.app/common/redirect/bizny-track?bizny_biznyweb"
                data-count="feature_3"
              >Download</a>
            </section>
            <picture>
              <source srcset="./assets/pic_bizny_web_03-375@2x.png 2x" media="(max-width: 375px)">
              <source srcset="./assets/pic_bizny_web_03-640@2x.png 2x" media="(max-width: 640px)">
              <img src="./assets/pic_bizny_web_03@1x.png" srcset="./assets/pic_bizny_web_03@2x.png 2x" :alt="$t('feature3')" />
            </picture>
          </section>
        </li>
        <li class="hide">
          <section class="container">
            <section class="slide_banner_title hide">
              <h2>{{ $t('feature4') }}</h2>
              <article>{{ $t('feature4_content') }}</article>
              <a
                class="dol_btn"
                href="https://media.bizny.app/common/redirect/bizny-track?bizny_biznyweb"
                data-count="feature_4"
              >Download</a>
            </section>
            <picture>
              <source srcset="./assets/pic_bizny_web_04-375@2x.png 2x" media="(max-width: 375px)">
              <source srcset="./assets/pic_bizny_web_04-640@2x.png 2x" media="(max-width: 640px)">
              <img src="./assets/pic_bizny_web_04@1x.png" srcset="./assets/pic_bizny_web_04@2x.png 2x" :alt="$t('feature4')" />
            </picture>
          </section>
        </li>
        <li class="hide">
          <section class="container">
            <section class="slide_banner_title hide">
              <h2>{{ $t('feature5') }}</h2>
              <article>{{ $t('feature5_content') }}</article>
              <a
                class="dol_btn"
                href="https://media.bizny.app/common/redirect/bizny-track?bizny_biznyweb"
                data-count="feature_5"
              >Download</a>
            </section>
            <picture>
              <source srcset="./assets/pic_bizny_web_05-375@2x.png 2x" media="(max-width: 375px)">
              <source srcset="./assets/pic_bizny_web_05-640@2x.png 2x" media="(max-width: 640px)">
              <img src="./assets/pic_bizny_web_05@1x.png" srcset="./assets/pic_bizny_web_05@2x.png 2x" :alt="$t('feature5')" />
            </picture>
          </section>
        </li>
        <li class="hide">
          <section class="container">
            <section class="slide_banner_title hide">
              <h2>{{ $t('feature6') }}</h2>
              <article>{{ $t('feature6_content') }}</article>
              <a
                class="dol_btn"
                href="https://media.bizny.app/common/redirect/bizny-track?bizny_biznyweb"
                data-count="feature_6"
              >Download</a>
            </section>
            <picture>
              <source srcset="./assets/pic_bizny_web_06-375@2x.png 2x" media="(max-width: 375px)">
              <source srcset="./assets/pic_bizny_web_06-640@2x.png 2x" media="(max-width: 640px)">
              <img src="./assets/pic_bizny_web_06@1x.png" srcset="./assets/pic_bizny_web_06@2x.png 2x" :alt="$t('feature6')" />
            </picture>
          </section>
        </li>
      </ul>
    </section>
    <a id="dol_btn_mobile" class="dol_btn" href="https://media.bizny.app/common/redirect/bizny-track?bizny_biznyweb" data-count="mobile">Download</a>
    <footer>
      <a id="dol_btn_footer" href="https://media.bizny.app/common/redirect/bizny-track?bizny_biznyweb"><img src="./assets/img_logo_bizny@1x.png" srcset="./assets/img_logo_bizny@2x.png 2x" alt="Bizny" width="24" /></a>
      <span>
        <a id="footer_mail" class="vertical_centering" href="mailto:bizny.feedback@gmail.com"><svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g>
                <rect x="0" y="0" width="20" height="20"></rect>
                <g transform="translate(1.000000, 3.000000)" stroke="#FFFFFF">
                    <path d="M17.1372724,0.860258147 L0.862727626,0.860258147 L0.862727626,13.5602795 L17.1372724,13.5602795 L17.1372724,0.860258147 Z" id="Rectangle-15" stroke-width="1.7"></path>
                    <path d="M1.15539418,9.70319922 L0.212341775,8.76063361 L8.30695421,0.956996871 C8.69390452,0.583956201 9.30652163,0.58351352 9.69401066,0.955994576 L17.8131135,8.76063361 L17.8131135,8.76063361 L16.8700611,9.70319922 L9,2.17496895 L1.15539418,9.70319922 Z" id="Path-18" stroke-width="0.5" fill="#FFFFFF" stroke-linejoin="bevel" transform="translate(9.012728, 5.190062) scale(1, -1) translate(-9.012728, -5.190062) "></path>
                </g>
            </g>
        </g>
        </svg>bizny.feedback@gmail.com</a>
        <span id="footer_lang" class="hide">
          <span>{{ langCurrent }}</span>
          <ul>
            <li></li>
          </ul>
        </span>
      </span>
    </footer>
  </div>
</template>

<script>
import style from "./style/media.css";
import { isMobile, isTablet, isIOS, isAndroid } from "mobile-device-detect";

import slideBanner from "./lib/slide_banner";

import store from "./store";

function hreflangInit(t) {
  var tmpArray = [];
  var xDefault = {
    rel: 'alternate',
    hreflang: 'x-default',
    href: store.state.url + '/' + t.locale
  };
  tmpArray.push(xDefault);
  for (let x in store.state.langList) {
    var tmpObj = {
      rel: 'alternate',
      hreflang: x,
      href: store.state.url + '/' + x
    };
    tmpArray.push(tmpObj);
  }
  return tmpArray
}

let links = function (t) {
  let tmpLinks = [
    {
      'rel': 'canonical',
      'href': store.state.url + '/' + t.locale + '/'
    },
    {
      'rel': 'alternate',
      'media': 'only screen and (max-width: 640px)',
      'href': store.state.url + '/' + t.locale + '/'
    },
    {
      'rel': 'alternate',
      'media': 'handheld',
      'href': store.state.url + '/' + t.locale + '/'
    }
  ]
  return tmpLinks.concat(hreflangInit(t))
}


export default {
  name: 'app',
  metaInfo() {
    return {
      title: this.$i18n.t('title'), // set a title
      meta: [
        {
          'http-equiv': 'content-language',
          'content': this.$i18n.locale
        },
        {
          'property': 'og:title',
          'content': this.$i18n.t('title')
        },
        {
          'property': 'og:url',
          'content': store.state.url + '/' + this.$i18n.locale + '/'
        },
        {
          'property': 'og:image',
          'content': store.state.url + '/' + 'img_banner_bizny.jpg?t=2020042901'
        },
        {
          'property': 'og:image:width',
          'content': '600'
        },
        {
          'property': 'og:image:height',
          'content': '315'
        },
        {
          'property': 'og:type',
          'content': 'website'
        },
        {
          'property': 'og:site_name',
          'content': 'Bizny'
        },
        {
          'name': 'twitter:card',
          'content': 'summary'
        },
        {
          'name': 'twitter:title',
          'content': this.$i18n.t('title')
        },
        {
          'name': 'twitter:description',
          'content': this.$i18n.t('description')
        },
        {
          'name': 'twitter:image',
          'content': '/img_banner_bizny.jpg'
        },
        {
          'property': 'og:description',
          'content': this.$i18n.t('description')
        },
        {
          'name': 'description',
          'content': this.$i18n.t('description')
        },
        {
          'name': 'apple-mobile-web-app-status-bar-style',
          'content': 'white'
        },
        {
          'name': 'apple-mobile-web-app-capable',
          'content': 'yes'
        }
      ],
      link: links(this.$i18n)
    }
  },
  mounted() {
    if (isMobile || isTablet) {
      document.body.classList.add('mobile')
    }
    slideBanner({
      time: 10000,
      container: document.querySelector('#slide_banner'),
      isLoop: true
      // initCount: 5
    });
    var dolBtn = document.querySelectorAll('.dol_btn');
    for (var i = 0; i < dolBtn.length; i++) {
      dolBtn[i].addEventListener('click', function () {
        ga('send', 'event', 'Google Play', 'click', this.getAttribute('data-count'));
      })
    }
    document.querySelector('#dol_btn_footer').addEventListener('click', function () {
      ga('send', 'event', 'Google Play', 'click', 'footer');
    })
  },
  data() {
    return {
      h1: "Boost Your Business",
      langCurrent: this.$i18n.locale
    };
  }
};
</script>

