import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

// import Banner from './views/Banner.vue'

Vue.use(Router)

var routesArray = [];

var langList = store.state.langList

function urlBanner () {
  var tmpArray = []
  for (let x in langList) {
    var tmpObj = {
      path: '/' + x + '/',
      name: 'banner_' + x
      // component: Banner
    }
    tmpArray.push(tmpObj)
  }
  return tmpArray;
}

routesArray = [].concat(urlBanner())

export function createRouter () {
  return new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routesArray
  })
}
